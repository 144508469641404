/* eslint-disable react/destructuring-assignment */
import React, { useMemo } from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { Col, Row, Hidden } from 'react-grid-system'
import styled from 'styled-components'

import { QuoteDataType, SingleFlightType } from 'types'

import flightClassNames from 'helpers/flightClasses'

import Tooltip from 'styles/Tooltip'
import Box from 'styles/Box'
import { H3 } from 'styles/Heading'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'reducers'
import { sameDay } from 'helpers/sameDay'

const AdditionalDaysLabel = styled.span`
  background-color: ${({ theme }) => theme.color.neutral.lighter};
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
`

const FlightRow = styled.tr`
  &:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }
  padding-bottom: 0.25rem;
`

const FlightCell = styled.td`
  padding: 0 0.5rem;
  color: #2a2a2a;

  &:first-child {
    width: 15%;
  }
`

const FlightDate = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: #2a2a2a;
`

const DepartureTime = styled.span`
  position: relative;
  font-size: 1.1rem;
  font-weight: bold;
  color: #2a2a2a;
  &:before {
    content: '';
    border: 3px solid #e2e2e2;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -25px;
    top: 5px;
  }
`

const ArrivalTime = styled.span`
  position: relative;
  font-size: 1.1rem;
  font-weight: bold;
  color: #2a2a2a;
  &:before {
    content: '';
    border: 3px solid #e2e2e2;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -25px;
    top: 5px;
  }
`

const AirlineLabel = styled.span`
  display: block;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
`

const AirportDeparture = styled.span`
  &:before {
    content: '';
    border-left: 5px dotted #e2e2e2;
    border-radius: 0%;
    height: 30px;
    position: absolute;
    left: -19px;
    top: 0px;
  }
  margin-top: 0.25rem;
`

const RouteLabel = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1rem;
`

const LocationLabel = styled.span`
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 1rem;
`
const MobileFlightInfo = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
`

const SingleFlightContainer = styled.div`
  width: 100%;
  &:not(:last-child) {
    border-bottom: 2px solid #e6e6e6;
  }
`

const AirlineMobileImage = styled.img`
  position: absolute;
  vertical-align: bottom;
  margin-left: -27px;
`

const ImageContainer = styled.div`
  margin: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SingleFlight: React.FC<SingleFlightType> = (props: SingleFlightType) => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const departureDay = moment(props.departure).endOf('day')
  const arrivalDay = moment(props.arrival).endOf('day')
  const airlineImageName = props.airline.toUpperCase()
  const airlineProperName = props.airlineLabel

  const departureLocation = useMemo(() => {
    return props.airportDepartureCity
      ? `${props.airportDepartureCity} (${props.airportCodeDeparture})`
      : `${props.airportDeparture} (${props.airportCodeDeparture})`
  }, [props.airportDepartureCity, props.airportCodeDeparture, props.airportDeparture])

  const arrivalLocation = useMemo(() => {
    return props.airportArrivalCity
      ? `${props.airportArrivalCity} (${props.airportCodeArrival})`
      : `${props.airportArrival} (${props.airportCodeArrival})`
  }, [props.airportArrivalCity, props.airportCodeArrival, props.airportArrival])

  if (!itinerary) return null

  const dayIndex =
    itinerary.days.findIndex(({ dayDate }) => {
      if (!props.departure) return false
      return sameDay(new Date(dayDate), new Date(props.departure))
    }) + 1

  return (
    <>
      <Hidden xs sm md>
        <FlightRow key={props.id}>
          <FlightCell>
            <Hidden xs sm md>
              <ImageContainer>
                <img
                  src={`https://www.gstatic.com/flights/airline_logos/70px/${airlineImageName}.png`}
                  alt=""
                  width="36px"
                  style={{ verticalAlign: 'middle' }}
                />
                <AirlineLabel>{airlineProperName}</AirlineLabel>
              </ImageContainer>
            </Hidden>
          </FlightCell>
          <FlightCell>
            <FlightDate>
              {!itinerary.hideDates && props.departure && props.arrival && (
                <>
                  <Moment format="dd D MMM">{props.departure}</Moment> &#x2022;{' '}
                  <Moment format="HH:mm">{props.departure}</Moment> - <Moment format="HH:mm">{props.arrival}</Moment>
                </>
              )}

              {itinerary.hideDates && <span>{dayIndex > 0 ? `Dag ${dayIndex}` : '-'}</span>}
            </FlightDate>
            {!itinerary.hideDates && arrivalDay > departureDay && (
              <Tooltip content={<Moment format="DD MMMM YYYY - HH:mm">{props.arrival}</Moment>}>
                <AdditionalDaysLabel>+1</AdditionalDaysLabel>
              </Tooltip>
            )}
          </FlightCell>
          <FlightCell>
            <RouteLabel>
              {departureLocation} - {arrivalLocation}
            </RouteLabel>
          </FlightCell>

          <FlightCell>{props.flightNumber}</FlightCell>

          {props.flightClass && (
            <FlightCell>
              <Tooltip content={flightClassNames[props.flightClass]}>
                <span>{props.flightClass}</span>
              </Tooltip>
            </FlightCell>
          )}
        </FlightRow>
      </Hidden>
      <Hidden lg xl xxl>
        <SingleFlightContainer>
          <Box key={props.id} ml="2.5rem" mr="2.5rem" mb="1.8rem">
            <Row>
              <Col xs={12}>
                <Box m={0}>
                  <FlightDate>
                    <span>
                      {!itinerary.hideDates &&
                        (props.departure ? <Moment format="dd D MMM">{props.departure}</Moment> : <span>-</span>)}
                    </span>

                    {itinerary.hideDates && <span>{dayIndex > 0 ? `Dag ${dayIndex}` : '-'}</span>}
                  </FlightDate>
                </Box>
                <Box mt=".5rem" m={0}>
                  <DepartureTime>
                    {props.departure ? <Moment format="HH:mm">{props.departure}</Moment> : <span>-</span>}
                  </DepartureTime>
                </Box>

                <Box mt=".25rem" m={0}>
                  <LocationLabel>
                    <AirportDeparture>{departureLocation}</AirportDeparture>
                  </LocationLabel>
                </Box>
                <Box mt=".5rem" m={0}>
                  <ArrivalTime>
                    {props.arrival ? <Moment format="HH:mm">{props.arrival}</Moment> : <span>-</span>}
                    {!itinerary.hideDates && arrivalDay > departureDay && (
                      <Tooltip content={<Moment format="DD MMMM YYYY - HH:mm">{props.arrival}</Moment>}>
                        <AdditionalDaysLabel>+1</AdditionalDaysLabel>
                      </Tooltip>
                    )}
                  </ArrivalTime>
                </Box>

                <Box mt=".1rem" m={0}>
                  <LocationLabel>{arrivalLocation}</LocationLabel>
                </Box>

                <Box mt="2rem" m={0}>
                  <MobileFlightInfo>
                    <AirlineMobileImage
                      src={`https://middenoostenreizen.s3-eu-west-1.amazonaws.com/airlines/${airlineImageName}.png`}
                      alt=""
                      width="20px"
                      height="auto"
                    />
                    <span>
                      {[airlineProperName, props.flightNumber, props.flightClass].filter((item) => !!item).join(' - ')}
                    </span>
                  </MobileFlightInfo>
                </Box>
              </Col>
            </Row>
          </Box>
        </SingleFlightContainer>
      </Hidden>
    </>
  )
}

export default SingleFlight
